<template>
	<div>
		<v-container px-1>

			<v-flex xs12 lg12 xl12 md12 style="text-align-last: end;" class="no-print">
				<button @click="exportToExcel" style="color: white; background-color: #3C87CD; border: none; padding: 10px; border-radius: 5px; margin: 10px;">Export to Excel
					<v-icon right dark>mdi-file-excel</v-icon>
				</button>
			</v-flex>
			<!-- {{ total }} -->
			<v-layout row wrap class="mb-3">

				<v-flex xs12 lg4 xl4 md3 class="ma-4">
					<v-card>
						<v-card-title>
							<h3>Total USD : ${{ total[0].total_usd }} <span style="color: red;"></span></h3>
						</v-card-title>
					</v-card>

				</v-flex>
				<v-flex xs12 lg4 xl4 md3 class="ma-4">
					<v-card>
						<v-card-title>
							<h3>Total IQD : {{ (total[0].total_dinar).toLocaleString() }} <span style="color: red;"></span></h3>
						</v-card-title>
					</v-card>

				</v-flex>
				<v-flex xs12 lg4 xl4 md3 class="ma-4">
					<v-card>
						<v-card-title>
							<h3>Total Delivery : {{ total[0].total_delivery_price}} <span style="color: red;"></span></h3>
						</v-card-title>
					</v-card>
				</v-flex>
                
				<v-flex xs12 lg4 xl4 md3 class="ma-4">
					<v-card>
						<v-card-title>
							<h3>Total + Delivery : {{ (total[0].total_with_delivery).toLocaleString()}} <span style="color: red;"></span></h3>
						</v-card-title>
					</v-card>
				</v-flex>

			</v-layout>
			<v-layout style="overflow-x:auto;">
				<table class="report-table" style="width:100%;" border="1">
					<thead>
						<tr>
							<th>#</th>
							<th>{{$store.getters.language.data.orders.order_id}}</th>
							<th>Bag ID</th>
							<th>Order Date</th>
							<th>Packed Date</th>
							<th>Customer</th>
							<th>City</th>
							<th>Price $</th>
							<th>Price IQD</th>
							<th>Delivery</th>
							<th>Total</th>
							<th>User</th>
							<th>Status</th>
							<th>Order Note</th>

						</tr>
					</thead>
					<tbody>
						<tr v-for="(orderItem, itemIndex) in rows" :key="itemIndex" style="width:100%">
							<td>{{itemIndex+1}}</td>
							<td>{{orderItem.order_id}}</td>
							<td>{{orderItem.packed_order_id}}</td>
							<td>{{new Date(orderItem.order_date).toLocaleString()}}</td>
							<td>{{new Date(orderItem.packed_order_date).toLocaleString()}}</td>
							<td>{{ orderItem.customer_username }}</td>
							<td>{{ orderItem.customer_city }}</td>
							<td>$ {{orderItem.packed_order_total_price}}</td>
							<td>IQD {{orderItem.packed_order_total_dinar.toLocaleString()}}</td>
							<td>IQD {{orderItem.packed_order_delivery_price}}</td>
							<td>IQD {{orderItem.total_dinar.toLocaleString()}}</td>
							<td>{{orderItem.packer_username}}</td>
							<td>{{orderItem.packed_order_status}}</td>
							<td>{{orderItem.order_note}}</td>

						</tr>
					</tbody>
				</table>

			</v-layout>

		
		</v-container>

	</div>

</template>
<script>
	import requests from '../../../requests/reports.requests'
	import * as XLSX from 'xlsx'
	export default {
		data() {
			return {
				rows: [],
				bagrows: [],
				total: {},
				subjects: [],
				loading: false,
				dialog: false,
				student: [],
				deposits: [],
				student_subjects: [],
				total_price: 0,
				total_price_without_soldout_all: 0,
				total: []
			}
		},
		props: {
			query: {
				type: Object,
				default: () => { }
			}
		},
		mounted() {
			this.openReport()
		},
		methods: {
			openReport() {
				this.loading = true
				let query = this.$props.query
				// query.institute_id = this.$store.state.auth.institute.institute_id
				// from and to date existis in query object add time 00:00:00 to from and 23:59:59 to to
				console.log(query)
				// if (query.from) {
				//     query.from = new Date(query.from).setHours(0, 0, 0, 0)
				// }
				// if (query.to) {
				//     query.to = new Date(query.to).setHours(23, 59, 59, 999)
				// }
				requests.reportPacked(query).then(res => {
					console.log(res.data)
					this.rows = res.data.rows
					this.total = res.data.total

					console.log(res.data.rows)
					// this.student = res.data.student
					// this.deposits = res.data.deposit_list
					// this.student_subjects = res.data.student_subjects
					console.log("------------------")
					this.loading = false
				})
			},
			exportToExcel() {

				const rows_copy = this.rows.map(row => {
                   
					return {
						'Order ID': row.order_id,
						'Bag ID': row.packed_order_id,
						'Order Date': new Date(row.order_date).toLocaleString(),
                        'Packed Date': new Date(row.packed_order_date).toLocaleString(),
                        'Customer': row.customer_username,
                        'City': row.customer_city,
                        'Price $': row.packed_order_total_price,
                        'Price IQD': row.packed_order_total_dinar,
                        'Delivery': row.packed_order_delivery_price,
                        'Total': row.total_dinar,
                        'User': row.packer_username,
                        'Status': row.packed_order_status,
                        'Order Note': row.order_note,
         
					}
				})
				const worksheet = XLSX.utils.json_to_sheet(rows_copy);
				const workbook = XLSX.utils.book_new();
				XLSX.utils.book_append_sheet(workbook, worksheet, "All Order Items");
				XLSX.writeFile(workbook, "table_data22.xlsx");



			},
		},
	}
</script>
<style scoped>
	.report-table {
		width: 100%;
		border-collapse: collapse;
		border: 1px solid;

		text-align: start;
	}
	.report-table th {
		border: 1px solid;
		text-align: start !important;
		background: gray;
		color: white;
	}
	.report-table td {
		padding: 5px;
		border: 1px solid;
		/* text-align: left !important; */
	}
	.newpage {
		page-break-before: always;
	}
</style>