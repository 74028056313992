<template>
	<div>
		<v-container class="no-print">
			<v-layout row wrap>
				<h1 class="my-7">
					{{$store.getters.language.data.report.title}}
				</h1>
			</v-layout>
			<v-layout row wrap>
				<v-flex xs12 lg3 xl3 md4>
					<v-text-field label="From" type="date" dense outlined class="mx-1" v-model="query.from"></v-text-field>
				</v-flex>
				<v-flex xs12 lg3 xl3 md4>
					<v-text-field label="To" type="date" dense outlined class="mx-1" v-model="query.to"></v-text-field>
				</v-flex>

				<v-flex xs12 lg3 xl3 md4>
					<v-autocomplete :items="customers" v-model="query.customer_id" :label="$store.getters.language.data.customers.customer_username" item-text="customer_username" item-value="customer_id" dense outlined class="mx-1" clearable></v-autocomplete>
				</v-flex>
				<v-flex xs12 lg3 xl3 md4>
					<!-- btn get customers  -->
					<v-btn color="primary" @click="readCustomers">
						Get Customers
					</v-btn>
				</v-flex>
				<v-flex xs12 lg3 xl3 md4>
					<v-select class="mx-1" clearable :items="['slemani','hawler','karkuk','dhok','zaxo','baghdad','darbandixan','halabja','rania','others']" v-model="query.customer_city" dense outlined item-text="customer_city" item-value="customer_city" :return-object="false" :label="$store.getters.language.data.customers.customer_city">
					</v-select>
				</v-flex>
				<v-flex xs12 lg3 xl3 md4>
					<v-select class="mx-1" clearable :items="['elly_online_store','shein_store_iraq','pages']" v-model="query.customer_type" dense outlined item-text="customer_type" item-value="customer_type" :return-object="false" :label="$store.getters.language.data.customers.customer_type">
					</v-select>
				</v-flex>
				<v-flex xs12 lg3 xl3 md4>
					<v-select class="mx-1" :items="['pending','purchased','packed','incomplete','soldout','soldout_told','unarrived','delivered']" v-model="query.order_item_status" dense outlined item-text="order_item_status" item-value="order_item_status" :return-object="false" :label="$store.getters.language.data.order_items.order_item_status" clearable>
					</v-select>
				</v-flex>

			</v-layout>
			<v-layout row wrap>
				<v-flex xs12 lg3 md4 xl3 v-for="(item,index) in reports_list" :key="index">
					<v-card class="ma-1" @click="openReport(item.id)">
						<v-card-title>{{item.title}}</v-card-title>
						<v-card-text>
							<span class="primary--text" v-for="(i,inx) in splitString(item.query)" :key="inx">{{i}}*</span>
							<br>
							<span v-for="(i,inx) in splitString(item.optional)" :key="inx">{{i}}</span>
						</v-card-text>
					</v-card>
				</v-flex>
			</v-layout>
			<v-flex xs12 lg12 sm12 xl12 class="my-5">
				<v-btn color="primary" @click="print">
					Print
				</v-btn>
			</v-flex>
			<v-layout row wrap>
				<v-progress-linear v-if="loading" :indeterminate="true" color="primary" height="5"></v-progress-linear>
			</v-layout>
		</v-container>

		<CustomerReport :query="query" v-if="selectedReport == 1 && !loading"></CustomerReport>
		<ItemsReport :query="query" v-if="selectedReport == 2 && !loading"></ItemsReport>
		<EmployeesReport :query="query" v-if="selectedReport == 3 && !loading"></EmployeesReport>
		<PackedReport :query="query" v-if="selectedReport == 4 && !loading"></PackedReport>
	</div>
</template>

<script>
	import CustomerReport from './Reports/Customers.vue'
	import EmployeesReport from './Reports/Employees.vue'
	import ItemsReport from './Reports/Items.vue'
	import PackedReport from './Reports/Packed.vue'
	import requestsCus from '../../requests/customers.request.js'
	export default {
		components: {

			ItemsReport,
			CustomerReport,
			EmployeesReport,
            PackedReport
		},
		data() {
			return {

				query: {
					from: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
					to: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
				},
				id: '',
				loading: false,
				selectedReport: 0,
				customers: [],
				snackbar: {
					value: false,
					text: '',
					color: ''
				},
			}
		},
		computed: {
			reports_list() {
				return this.$store.getters.language.data.report.reports_list
			},
			users() {
				return this.$store.getters.users_list
			},


		},
		methods: {
			print() {
				const theme = this.$vuetify.theme.isDark
				this.$vuetify.theme.isDark = false
				setTimeout(() => {
					window.print()
					this.$vuetify.theme.isDark = theme

				}, 1000)
			},
			openReport(id) {
				this.loading = true
				this.selectedReport = id
				setTimeout(() => {
					this.selectedReport = id
					this.loading = false
				}, 1000);
			},
			splitString(str) {
				return str ? str.split(',') : []
			},
			readCustomers() {
				this.loading = true
				requestsCus.getAllCustomers().then(r => {
					if (r.status == 200) {
						this.customers = r.data.rows
						// this.blocked_numbers = r.data.blocked_numbers
						this.loading = false
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to read Customers',
							color: 'error'
						}
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Fail to read Customers',
							color: 'error'
						}
					})
					.finally(() => {
						this.loading = false
					})
			},
		},
	}
</script>

<style>
</style>