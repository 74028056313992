<template>
	<div>
		<v-container px-1>

			<v-flex xs12 lg12 xl12 md12 style="text-align-last: end;" class="no-print">
				<button @click="exportToExcel" style="color: white; background-color: #3C87CD; border: none; padding: 10px; border-radius: 5px; margin: 10px;">Export to Excel
					<v-icon right dark>mdi-file-excel</v-icon>
				</button>
			</v-flex>
		
			<v-layout row wrap class="mb-3">

				<v-flex xs12 lg5 xl5 md3 v-for="(status, index) in total_status" :key="index" class="ma-4">
					<v-card>
						<v-card-title>
							<h3>{{ status.order_item_status }} <span style="color: red;">{{ status.total }}</span> <span>
                                Price:  <span style="color: red;">$ {{ status.total_price }}</span>
                            </span></h3>
						</v-card-title>
					</v-card>

				</v-flex>
			</v-layout>
			<v-layout style="overflow-x:auto;" class="mb-4">
<!-- table cities -->
				<table class="report-table" style="width:100%;" border="1">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>City</th>
                            <th>Count</th>
                            <th>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(city, index) in total_cities" :key="index">
                            <td>{{ index + 1 }}</td>
                            <td>{{ city.customer_city }}</td>
                            <td>{{ city.total }}</td>
                            <td>$ {{ city.total_price }}</td>
                        </tr>
                    </tbody>
                </table>
                </v-layout>
			<v-layout style="overflow-x:auto;">
				<table class="report-table" style="width:100%;" border="1">
					<thead>
						<tr>
							<th>#</th>
							<th>{{$store.getters.language.data.orders.order_id}}</th>
							<th>{{$store.getters.language.data.report.date}}</th>
							<th>Customer</th>
							<th>{{$store.getters.language.data.order_items.order_sku_code}}</th>
							<th>
								Note
							</th>
							<th>Price</th>
							<th>Status</th>
							<th class="no-print">Link</th>
                            <th>City</th>
							<th>Inserted by</th>
							<th>Packed by</th>
							<th>Delivered by</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(orderItem, itemIndex) in rows" :key="itemIndex" style="width:100%">
							<td>{{itemIndex+1}}</td>
							<td>{{orderItem.order_id}}</td>
							<td>{{new Date(orderItem.order_item_date).toLocaleString()}}</td>
							<td>{{ orderItem.customer_username }}</td>
							<td>{{orderItem.order_sku_code}}</td>
							<td>
								{{ orderItem.order_item_note }}
							</td>
							<td>$ {{orderItem.order_item_price}}</td>

							<td>{{orderItem.order_item_status}}</td>
							<td class="no-print">
								<a :href="orderItem.order_item_link" target="_blank">
									<v-tooltip bottom>
										<template v-slot:activator="{ on }">
											<v-icon v-on="on" v-if="orderItem.order_item_link != null">mdi-link</v-icon>
											<v-icon v-if="orderItem.order_item_link == null" color="red" v-on="on">mdi-link</v-icon>
										</template>
										<span>{{ orderItem.order_item_link }}</span>
									</v-tooltip>

								</a>
							</td>
                            <td>
                                {{ orderItem.customer_city }}
                            </td>
							<td>
								{{ orderItem.inserted_by_user_username }}
							</td>
							<td>
								{{ orderItem.packed_by_user_username }}
							</td>
							<td>
								{{ orderItem.delivered_by_user_username }}
							</td>
						</tr>
					</tbody>
				</table>

			</v-layout>

			<v-layout>
				<h1 class="my-4">
					<!-- {{$store.getters.language.data.students.total_deposit}}: -->
					<!-- {{(student.total_deposit || 0).toLocaleString()}} -->
				</h1>
			</v-layout>
			<v-layout>

				<h2 class="my-3" dir="ltr">
					<!-- {{(student.student_loan || 0).toLocaleString()}} - {{(student.total_deposit || 0).toLocaleString()}} = <span class="primary--text">{{(student.total_balance  || 0).toLocaleString()}}</span> -->
				</h2>
			</v-layout>
		</v-container>

	</div>

</template>
<script>
	import requests from '../../../requests/reports.requests'
	import * as XLSX from 'xlsx'
	export default {
		data() {
			return {
				rows: [],
				bagrows: [],
				total: {},
				subjects: [],
				loading: false,
				dialog: false,
				student: [],
				deposits: [],
				student_subjects: [],
				total_price: 0,
				total_price_without_soldout_all: 0,
				total_status: [],
                total_cities: []
			}
		},
		props: {
			query: {
				type: Object,
				default: () => { }
			}
		},
		mounted() {
			this.openReport()
		},
		methods: {
			openReport() {
				this.loading = true
				let query = this.$props.query
				// query.institute_id = this.$store.state.auth.institute.institute_id
				// from and to date existis in query object add time 00:00:00 to from and 23:59:59 to to
				console.log(query)
				// if (query.from) {
				//     query.from = new Date(query.from).setHours(0, 0, 0, 0)
				// }
				// if (query.to) {
				//     query.to = new Date(query.to).setHours(23, 59, 59, 999)
				// }
				requests.reportItems(query).then(res => {
					console.log(res.data)
					this.rows = res.data.rows
					this.total_status = res.data.total_status
                    this.total_cities = res.data.total_cities
					console.log(res.data.rows)
					// this.student = res.data.student
					// this.deposits = res.data.deposit_list
					// this.student_subjects = res.data.student_subjects
					console.log("------------------")
					this.loading = false
				})
			},
			exportToExcel() {

				const rows_copy = this.rows.map(row => {
					return {
						'Order ID': row.order_id,
						'Date': new Date(row.order_item_date).toLocaleString(),
						'Customer': row.customer_username,
						'SKU Code': row.order_sku_code,
						'Note': row.order_item_note,
						'Price': row.order_item_price,
						'Status': row.order_item_status,
						'Link': row.order_item_link,
                        'City': row.customer_city,
						'Inserted by': row.inserted_by_user_username,
						'Packed by': row.packed_by_user_username,
						'Delivered by': row.delivered_by_user_username,
					}
				})
				const worksheet = XLSX.utils.json_to_sheet(rows_copy);
				const workbook = XLSX.utils.book_new();
				XLSX.utils.book_append_sheet(workbook, worksheet, "All Order Items");
				XLSX.writeFile(workbook, "table_data22.xlsx");



			},
		},
	}
</script>
<style scoped>
	.report-table {
		width: 100%;
		border-collapse: collapse;
		border: 1px solid;

		text-align: start;
	}
	.report-table th {
		border: 1px solid;
		text-align: start !important;
		background: gray;
		color: white;
	}
	.report-table td {
		padding: 5px;
		border: 1px solid;
		/* text-align: left !important; */
	}
	.newpage {
		page-break-before: always;
	}
</style>