<template>
	<div>
		<v-container px-1>

			<!-- [ { "user_username": "lare", "inserted_by": 5, "total": 2 }, { "user_username": "Niar", "inserted_by": 9, "total": 5 } ] [ { "user_username": "lare", "packed_by": 5, "total": 1 }, { "user_username": "Niar", "packed_by": 9, "total": 6 } ] [ { "user_username": "Niar", "delivered_by": 9, "total": 7 } ]
 -->
			<v-layout row wrap>
				<v-flex xs12 lg12 xl2 md12 class="ma-4">
					<!-- create a cart write all rows of total_users_inserted -->
					<v-card>
						<v-card-title>
							<h3>Inserted by</h3>
						</v-card-title>
						<v-card-text>
							<v-layout row wrap>
								<v-flex xs12 lg2 xl2 md3 v-for="(user, index) in total_users_inserted" :key="index" class="ma-4">
									<v-card>
										<v-card-title>
											<h3>{{ user.inserted_by_user_username }} <span style="color:red">{{ user.total }}</span></h3>
										</v-card-title>

									</v-card>

								</v-flex>
							</v-layout>
						</v-card-text>
					</v-card>
				</v-flex>
				<v-flex xs12 lg12 xl2 md12 class="ma-4">
					<v-card>
						<v-card-title>
							<h3>Packed by</h3>
						</v-card-title>
						<v-card-text>
							<v-layout row wrap>
								<v-flex xs12 lg2 xl2 md3 v-for="(user, index) in total_users_packed" :key="index" class="ma-4">
									<v-card>
										<v-card-title>
											<h3>{{ user.packed_by_user_username }} <span style="color:red">{{ user.total }}</span></h3>
										</v-card-title>

									</v-card>

								</v-flex>
							</v-layout>
						</v-card-text>
					</v-card>
				</v-flex>
				<v-flex xs12 lg12 xl2 md12 class="ma-4">
					<v-card>
						<v-card-title>
							<h3>Delivered by</h3>
						</v-card-title>
						<v-card-text>
							<v-layout row wrap>
								<v-flex xs12 lg2 xl2 md3 v-for="(user, index) in total_users_delivered" :key="index" class="ma-4">
									<v-card>
										<v-card-title>
											<h3>{{ user.delivered_by_user_username }} <span style="color:red">{{ user.total }}</span></h3>
										</v-card-title>

									</v-card>

								</v-flex>
							</v-layout>
						</v-card-text>
					</v-card>
				</v-flex>
			</v-layout>

		</v-container>

	</div>

</template>
<script>
	import requests from '../../../requests/reports.requests'
	import * as XLSX from 'xlsx'
	export default {
		data() {
			return {
				rows: [],
				bagrows: [],
				total: {},
				subjects: [],
				loading: false,
				dialog: false,
				student: [],
				deposits: [],
				student_subjects: [],
				total_price: 0,
				total_price_without_soldout_all: 0,
				total_status: [],
				total_users: [],
				total_users_inserted: [],
				total_users_packed: [],
				total_users_delivered: []
			}
		},
		props: {
			query: {
				type: Object,
				default: () => { }
			}
		},
		mounted() {
			this.openReport()
		},
		methods: {
			openReport() {
				this.loading = true
				let query = this.$props.query
				// query.institute_id = this.$store.state.auth.institute.institute_id
				// from and to date existis in query object add time 00:00:00 to from and 23:59:59 to to
				console.log(query)
				// if (query.from) {
				//     query.from = new Date(query.from).setHours(0, 0, 0, 0)
				// }
				// if (query.to) {
				//     query.to = new Date(query.to).setHours(23, 59, 59, 999)
				// }
				requests.reportUsers(query).then(res => {
					console.log(res.data)
					this.rows = res.data.rows
					this.total_status = res.data.total_status
					this.total_users = res.data.total_users
					this.total_users_inserted = res.data.total_users_inserted
					this.total_users_packed = res.data.total_users_packed
					this.total_users_delivered = res.data.total_users_delivered

					console.log(res.data.rows)
					// this.student = res.data.student
					// this.deposits = res.data.deposit_list
					// this.student_subjects = res.data.student_subjects
					console.log("------------------")
					this.loading = false
				})
			},
			exportToExcel() {

				const rows_copy = this.rows.map(row => {
					return {
						'Order ID': row.order_id,
						'Date': new Date(row.order_item_date).toLocaleString(),
						'Customer': row.customer_username,
						'SKU Code': row.order_sku_code,
						'Note': row.order_item_note,
						'Price': row.order_item_price,
						'Status': row.order_item_status,
						'Link': row.order_item_link,
						'Inserted by': row.inserted_by_user_username,
						'Packed by': row.packed_by_user_username,
						'Delivered by': row.delivered_by_user_username,
					}
				})
				const worksheet = XLSX.utils.json_to_sheet(rows_copy);
				const workbook = XLSX.utils.book_new();
				XLSX.utils.book_append_sheet(workbook, worksheet, "All Order Items");
				XLSX.writeFile(workbook, "table_data22.xlsx");



			},
		},
	}
</script>
<style scoped>
	.report-table {
		width: 100%;
		border-collapse: collapse;
		border: 1px solid;

		text-align: start;
	}
	.report-table th {
		border: 1px solid;
		text-align: start !important;
		background: gray;
		color: white;
	}
	.report-table td {
		padding: 5px;
		border: 1px solid;
		/* text-align: left !important; */
	}
	.newpage {
		page-break-before: always;
	}
</style>