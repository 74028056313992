<template>
	<div>
		<v-container px-1>

			<!-- <v-layout>
				<h1>
					{{$store.getters.language.data.customers.customer_username}}:
					{{rows.customer_username}}
				</h1>
			</v-layout>

			<h3>
				{{$store.getters.language.data.customers.customer_phone}}:
				{{rows.customer_phone}}
			</h3>
			<h3>
				{{$store.getters.language.data.customers.customer_phone_2}}:
				{{rows.customer_phone_2}}
			</h3>
			<h3>
				{{$store.getters.language.data.customers.customer_city}}:
				{{rows.customer_city}}
			</h3>
			<h3>
				{{$store.getters.language.data.customers.customer_address}}:
				{{rows.customer_address}}
			</h3> -->
			<!-- <h3>
				{{$store.getters.language.data.customers.customer_type}}:
				{{rows.customer_type}}
			</h3> -->
				<v-flex xs12 lg12 xl12 md12 style="text-align-last: end;" class="no-print">
            		<button @click="exportToExcel" style="color: white; background-color: #3C87CD; border: none; padding: 10px; border-radius: 5px; margin: 10px;"
         >Export to Excel
         <v-icon right dark>mdi-file-excel</v-icon>
         </button>
                </v-flex>
			<h3>
				Total All Orders:
				${{total_price.toFixed(2)}}
			</h3>
			<h3>
				Total All Orders Without Soldout Items:
				${{total_price_without_soldout_all.toFixed(2)}}
			</h3>

			<v-layout>
				<h1 class="my-6">
					Orders:
				</h1>
			</v-layout>

			<div>
				<div v-for="(item, index) in bagrows" :key="index" class="newpage">
					<v-divider class="my-3"></v-divider>
					<h1># {{index+1}}</h1>
					<h3> {{$store.getters.language.data.customers.customer_username}} : {{item.customer_username}}</h3>
					<h3> {{$store.getters.language.data.customers.customer_type}} : {{item.customer_type}}</h3>
					<h3> {{$store.getters.language.data.customers.customer_city}} : {{item.customer_city}}</h3>
					<h3> {{$store.getters.language.data.customers.customer_address}} : {{item.customer_address}}</h3>
					<h3> {{$store.getters.language.data.customers.customer_phone}} : {{item.customer_phone}}</h3>
					<h3> {{$store.getters.language.data.customers.customer_phone_2}} : {{item.customer_phone_2}}</h3>
					<h4> {{$store.getters.language.data.orders.order_id}} : {{item.order_id}}</h4>
					<h4>{{$store.getters.language.data.orders.order_date}} : {{new Date(item.order_date).toLocaleString()}}</h4>
					<h4>{{$store.getters.language.data.orders.order_note}} : {{item.order_note}}</h4>
					<!-- shipping prive -->
					<h4>{{$store.getters.language.data.orders.order_shipping_price}} : ${{item.order_shipping_price}}</h4>
					<h3>
						Total Pending Items:
						${{item.total_pending_price.toFixed(2)}}
					</h3>
					<h3>
						Total Purchased Items:
						${{item.total_purchased_price.toFixed(2)}}
					</h3>
					<h3>
						Total Packed Items:
						${{item.total_packed_price.toFixed(2)}}
					</h3>
					<h3>
						Total Delivered Items:
						${{item.total_delivered_price.toFixed(2)}}
					</h3>
					<h3>
						Total Price Without Soldout Items:
						${{item.total_price_without_soldout.toFixed(2)}}
					</h3>
					<h3 class="mb-4">Total Price : ${{item.total_price_with_shipping.toFixed(2)}}</h3>

					<v-layout style="overflow-x:auto;">
						<table class="report-table" style="width:100%;" border="1">
							<thead>
								<tr>
									<th>#</th>
									<th>{{$store.getters.language.data.orders.order_id}}</th>
									<th>{{$store.getters.language.data.report.date}}</th>
									<th>{{$store.getters.language.data.order_items.order_sku_code}}</th>
									<th>{{$store.getters.language.data.order_items.order_item_price}}</th>
									<th>Qty</th>
									<th>Total Price</th>
									<th>{{$store.getters.language.data.order_items.order_item_status}}</th>
									<th class="no-print">{{$store.getters.language.data.order_items.order_item_link}}</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(orderItem, itemIndex) in item.order_items" :key="itemIndex" style="width:100%">
									<td>{{itemIndex+1}}</td>
									<td>{{orderItem.order_id}}</td>
									<td>{{new Date(orderItem.order_item_date).toLocaleString()}}</td>
									<td>{{orderItem.order_sku_code}}</td>
									<td>$ {{orderItem.order_item_price}}</td>
									<td>{{orderItem.order_item_quantity}}</td>
									<!-- td orer_itemP-rice * order_item_quantity if not null -->
									<td>$ {{orderItem.order_item_price * orderItem.order_item_quantity}}</td>
									<td>{{orderItem.order_item_status}}</td>
									<!-- order_item link with icon cliackable -->
									<td class="no-print">
										<a :href="orderItem.order_item_link" target="_blank">
											<v-tooltip bottom>
												<template v-slot:activator="{ on }">
													<v-icon v-on="on" v-if="orderItem.order_item_link != null">mdi-link</v-icon>
													<v-icon v-if="orderItem.order_item_link == null" color="red" v-on="on">mdi-link</v-icon>
												</template>
												<span>{{ orderItem.order_item_link }}</span>
											</v-tooltip>

										</a>
									</td>
								</tr>
							</tbody>
						</table>

					</v-layout>
				</div>
			</div>

			<v-layout>
				<h1 class="my-4">
					<!-- {{$store.getters.language.data.students.total_deposit}}: -->
					<!-- {{(student.total_deposit || 0).toLocaleString()}} -->
				</h1>
			</v-layout>
			<v-layout>

				<h2 class="my-3" dir="ltr">
					<!-- {{(student.student_loan || 0).toLocaleString()}} - {{(student.total_deposit || 0).toLocaleString()}} = <span class="primary--text">{{(student.total_balance  || 0).toLocaleString()}}</span> -->
				</h2>
			</v-layout>
		</v-container>


	</div>

</template>
<script>
	import requests from '../../../requests/reports.requests'
	import * as XLSX from 'xlsx'
	export default {
		data() {
			return {
				rows: [],
				bagrows: [],
				total: {},
				subjects: [],
				loading: false,
				dialog: false,
				student: [],
				deposits: [],
				student_subjects: [],
				total_price: 0,
				total_price_without_soldout_all: 0,
			}
		},
		props: {
			query: {
				type: Object,
				default: () => { }
			}
		},
		mounted() {
			this.openReport()
		},
		methods: {
			openReport() {
				this.loading = true
				let query = this.$props.query
				// query.institute_id = this.$store.state.auth.institute.institute_id
				// from and to date existis in query object add time 00:00:00 to from and 23:59:59 to to
				console.log(query)
				// if (query.from) {
				//     query.from = new Date(query.from).setHours(0, 0, 0, 0)
				// }
				// if (query.to) {
				//     query.to = new Date(query.to).setHours(23, 59, 59, 999)
				// }
				requests.CustomersReport(query).then(res => {
					this.rows = res.data.rows[0]
					this.bagrows = res.data.bagrows
					this.total_price = res.data.total_price
					this.total_price_without_soldout_all = res.data.total_price_without_soldout_all

					console.log(res.data)
					// this.student = res.data.student
					// this.deposits = res.data.deposit_list
					// this.student_subjects = res.data.student_subjects
					console.log("------------------")
					this.loading = false
				})
			},
			exportToExcel() {
				// const workbook = XLSX.utils.book_new();

				const allOrderItems = this.bagrows.flatMap((bag) => bag.order_items);
				const worksheet = XLSX.utils.json_to_sheet(allOrderItems);
				const workbook = XLSX.utils.book_new();
				XLSX.utils.book_append_sheet(workbook, worksheet, "All Order Items");
				XLSX.writeFile(workbook, "table_data.xlsx");

				// Loop through each bag and create a one sheet for all order items
				// this.bagrows.forEach((bag, bagIndex) => {
				//     const worksheet = XLSX.utils.json_to_sheet(bag.order_items);
				//     XLSX.utils.book_append_sheet(workbook, worksheet, bagIndex + 1);
				// });

				// XLSX.writeFile(workbook, "table_data.xlsx");
			},
		},
	}
</script>
<style scoped>
	.report-table {
		width: 100%;
		border-collapse: collapse;
		border: 1px solid;

		text-align: start;
	}
	.report-table th {
		border: 1px solid;
		text-align: start !important;
		background: gray;
		color: white;
	}
	.report-table td {
		padding: 5px;
		border: 1px solid;
		/* text-align: left !important; */
	}
	.newpage {
		page-break-before: always;
	}
</style>